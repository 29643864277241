import { getImageSrcUrl } from '@components/Image/Image';
import { ProductCard } from '@components/ProductCard';
import { BaseProduct } from '@interfaces/Product';

import { BulkCarousel } from './Carousel';

const createStructuredCarouselData = (products: BaseProduct[]) => ({
  '@context': 'https://schema.org',
  '@type': 'ItemList',
  itemListElement: products.map((p, idx) => ({
    '@type': 'ListItem',
    position: idx + 1,
    item: {
      '@type': 'Product',
      name: p.name,
      image: p.images.map((img) => getImageSrcUrl({ image: img, width: 200 })),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: p.averageRating,
        reviewCount: p.totalReviews,
      },
      url: 'https://www.bulknutrients.com.au' + p.slug,
      offers: {
        price: p.pricing.price.value,
        priceCurrency: 'AUD',
      },
    },
  })),
});

export function FPTProductsCarousel({
  relatedProducts,
  theme,
}: {
  relatedProducts: BaseProduct[];
  theme: string;
}) {
  if (relatedProducts.length === 0) {
    return <></>;
  }

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(createStructuredCarouselData(relatedProducts)),
        }}
      />
      <div
        data-theme={theme}
        className="max-w-screen full-bleed col-span-full bg-transparent pt-5
      text-grey-dark data-[theme=kidActiv]:bg-blue
      data-[theme=plant]:bg-plant
      data-[theme=kidActiv]:text-white
      data-[theme=plant]:text-white
      dark:bg-black
      dark:data-[theme=plant]:bg-plant
      "
      >
        <h3 className="text-center font-bold italic text-inherit dark:text-white">
          Customers also bought
        </h3>
        <BulkCarousel
          testId="fpt-carousel"
          className="light-background my-5"
          fadeTo="to-white dark:to-black"
        >
          {relatedProducts.map((item) => (
            <ProductCard
              product={item}
              key={item.entityId}
              className="w-[320px] tiny:w-[400px]"
            />
          ))}
        </BulkCarousel>
      </div>
    </>
  );
}
